.user-editor {
    section{
        margin-bottom: 1.5rem;
        h4{
            margin-bottom: 1rem;
        }
    }
    .all-locations{
        margin: 1rem 0.5rem;

        .form-check {
            max-width: 200px!important;
        }
    }

    .form-controls{
        display: flex;
        justify-content: right;
        column-gap: 2em;
    }

    label{
        font-weight: bold;
    }
    label.form-check-label{
        font-weight: normal;
        font-size: 0.8rem;
        position: relative;
        top: -2px;
    }


}
