.primary-nav{
    padding-top: 1rem;
    a{
        text-decoration: none;
    }
    .nav-item{
        padding: 0 0 0.5rem 0rem;
    }

    .accordion-item{
        border: none;

        .accordion-button:not(.collapsed){
            background-color: inherit;
            box-shadow: none;
        }
        .accordion-button:focus{
            border: none;
            box-shadow: none;
        }
        .accordion-button{
            padding: 0.5rem 0;
        }
        .accordion-body{
            padding-top: 0.25rem;
        }

        .accordion-body{
            .nav-item{
                padding: 0 0 0.5rem 1rem;
            }
        }
    }
}