@import "../../../Variables.scss";

.search-criteria{
    .checklist-scroll{
        margin-top: 0.6rem;
        max-height: 195px;
        overflow-y: scroll;

        .form-check{
            max-width: 100%!important;
            label{
                font-size: 0.9rem;
                line-height: 1rem;
                position: relative;
                top: -1px;
            }
        }
    }
}



.report{
    margin-top: 3rem;

    // Force the table header to use wrapping 
    .rdt_TableCol_Sortable{
        div{
            overflow: initial;
            white-space: initial;
            text-align: center;
        }
    }
    .data-table.total {
        .rdt_TableHeadRow{
            display: none;
        }
        .rdt_TableRow{
            background-color: #DDD!important;
        }
    }

    .csv{
        max-width: 160px;
        a{
            color: $rgcLightBlue;
        }
    }
   .pie-chart{
        
        label{
           width: 100%;
           text-align: center;
           font-weight: bold!important;
        }
        svg{
            display: flex;
            margin: 0 auto;
        }
   }

    .header{
       
        margin-bottom: 1rem;
      
        h3{
            display: inline-block;
            width: 140px;
        }

        .visualization-check{
            text-align: right;
            width: 160px;
        }
    }
    

    .form-control[readonly] {
        background-color: transparent;
        font-size: 0.8rem;
    }

    .report-locations{
        border: 1px solid #1a3f70;
        border-radius: 5px;
        padding: 0.5rem 0.7rem;
        font-size: 0.8rem;

        span:not(:last-child)::after{
            content: ', '
        }
    }



    .ext-form section {
        h4{
            font-size: 0.9rem;
            padding: 0.1rem 0.3rem;
            padding-top: 0.3rem;
            border-radius: 5px;
        }
        label{
            font-weight: initial;
            line-height: 1.2rem;
        }

        .form-control{
            border: 1px solid #1a3f70;
            border-radius: 5px;
        }

        [data-column-id="1"]{
           flex: 3;
        }
        [data-column-id="2"]{
            flex: 1;
            max-width: 50px;
        }
    }
}


.report-details{
    border: 1px solid #1a3f70;
    margin: 0.2rem;
    border-radius: 5px;
}


@media screen and (min-width: 768px) {
    .report{
        .ext-form section {
            [data-column-id="1"]{
                max-width: 320px;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .report{
        .ext-form section {
            [data-column-id="1"]{
                flex: 4;
             }
             [data-column-id="2"]{
                 flex: 1;
             }
        }
    }
}

@media screen and (max-width: 575px) {
    .report{
        .ext-form section {
            [data-column-id="1"]{
                max-width: 400px;
             }
           
        }
    }
}

@media screen and (max-width: 500px) {
    .report{
        .ext-form section {
            [data-column-id="1"]{
                max-width: 300px;
             }
           
        }
    }
}


@media screen and (max-width: 420px) {
    .report{
        .ext-form section {
            [data-column-id="1"]{
                max-width: 240px;
             }
           
        }
    }
}