@import "~bootstrap/scss/bootstrap";
@import "./Variables";

body {
  font-size: 0.95rem;
}

h1{
  font-size: calc(1rem + 1.5vw);
  margin-bottom: 1rem;
}
h2{
  font-size: calc(1rem + 0.9vw)
}
h3{
  font-size: calc(1rem + 0.5vw)
}
h1, h2, h3, h4, h5, h6{
  color: $rgcDarkBlue;
  font-family: 'Gothic A1', sans-serif;
  font-weight: bold;
}

a, button, .accordion-button:not(.collapsed),
.btn-link, .nav-link{
  color: $linkColor;
  text-decoration: none;
  border: none;
}

a.callout{
  color: $calloutLink;
}

 a:hover, .btn-link:hover, .btn-link:focus,
 .nav-tabs .nav-link.hover,
 .accordion-button:hover {
  color: $linkHoverColor;
  box-shadow: none;
}

.nav-tabs .nav-link.active{
  color: $rgcDarkBlue;
}

.btn-primary{
  background-color: $rgcDarkBlue;
  border-color: $rgcDarkBlue;
  color: #fff;
}

.btn-primary:hover{
  background-color: $rgcLightBlue;
  border-color: $rgcLightBlue;
  color: #333;
}

.btn-warning{
  background-color: $rgcOrange;
  border-color:  $rgcOrange;
}
.btn-warning:hover{
  background-color: $rgcRed;
  border-color:   $rgcOrange;
}

.content{
  padding-top: 1rem;
}

.success{
  color: #33bb33;
}
.error{
  margin: 0.5rem 0 0 0 ;
  color: #ff0000;
  font-weight: bold;
}
p.error{
  font-size: 10pt;
}

p.help{
  font-size: 10pt;
  color: #999999;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.react-datepicker-wrapper{
  input{
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.375rem 0 0.375rem 0.75rem;
  }
}
.react-datepicker__triangle{
  //left: -20px!important;
  transform: translate3d(100px, 0px, 0px)!important;
}
.react-datepicker-popper{
  z-index: 10!important;
}


.row-details{ 
  border-bottom: solid 2px #333;
  border-top: solid 1px #333;
  background-color: #f1f7fa;

  padding: 0.5rem;

  label {
    font-size: 0.8rem;
  }
  p {
    font-size: 0.7rem;
    margin: 0 0 0 0;
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    font-weight: bold;
  }
  .col-form-label, p{
    padding-top:0;
    padding-bottom: 0.2rem;
  }
}

.expandCollapseTable{
  margin-bottom: 0;
  margin-top: 1rem;

  a{
    //color: #0d6efd!important;
    cursor: pointer;
  }
}

.form-check-input:checked{
  background-color: $rgcDarkBlue;
  border-color: $rgcDarkBlue;
}

input:disabled{
  background-color: #e9ecef;
}


.footer{
  margin-top: 2rem;
  font-size: 0.8rem;
  padding: 0.4rem;
  padding-top:1rem;
  background-color: #526D6F99;

  a{
    color: $rgcLightGreen;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}


@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
