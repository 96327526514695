.header-main{
   
    border-bottom: solid 2px #333;
   
    img{
        max-width: 200px;
    }

    .header-items{
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
    }

    #mobile-nav{
        border-top: 2px solid #000;
        margin-top: 1rem;
    }
    .mobile-menu-button{
        color: #333;
        font-size: 2rem;
    }
}