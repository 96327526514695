@import "../../Variables.scss";

.input-form{
    position: relative;
    margin-bottom: 2rem;

    .edit-switch{
        position: absolute;
        top: 0;
        right: 0;
    }

}

.ext-form{
    .form-check {
        max-width: 240px !important;
    }
    .form-switch input, .form-switch label{
        cursor: pointer;
    }
    .form-switch label:hover{
        color: $rgcOrange;
    }
    max-width: 800px;
  
    .row{
        align-items: flex-start;
        justify-content: left;
        padding: 0 0 0 1rem ;

    }
   
    .duration-wrapper{
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        margin: 0 0 0 1rem;
        padding: 1rem 0;  
        .duration-display{
            max-width: 180px;
        }
    }

    section{
       
        h4{
            color: #fff;
            background-color: $rgcDarkBlue;
            border: 2px solid $rgcDarkBlue;
            border-radius: 8px;
            padding: 0.2rem 0.4rem;
            padding-top: 0.3em;
            font-size: 1.1rem;
            margin-bottom: 0.8rem;
        }
    }
    
    .child.row{
        padding-right: 0;
        .row{
         //   margin-bottom: 0!important;
           
            section{
                padding-top:0.5rem;
            }
        }
        section{
            padding-top: 0.5rem;
            padding-right: 0;
            h4{
                //background-color: #efefef;
                font-size: 1rem;
                padding: 0.2rem 0.3rem;
                margin-bottom: 0.4rem;
            }
        }
    }

   

    .time-display{
        display: inline-block;
            margin: 0 0 0 0 ;
            padding: 0.375rem 2.25rem 0.375rem 0.375rem;
      
    }

    
    .form-controls{
        display: flex;
        justify-content: right;
        column-gap: 2em;
    }

    label{
        font-weight: bold;
    }

    .col-form-label{
        padding-top: 0;
        padding-bottom: 0;
    }

    .hint{
        font-size: 0.8rem;
        color: #999;
    }

    .form-check{
        label{
            font-weight: normal;
        }
    }
    .row.text-input-label{
        text-align: left;
        padding-top: 0;
        label {
            margin-bottom: 0.5rem;
            padding-left: 0.5rem;
        }
    }
    
    .row.text-input-control{
        padding: 0 0 0 2rem;
    }

    .check.row{
        padding: 0 0 0 2.5rem ;
    }
    
    .radio{
        padding-top:0.5rem;
    }
  }
  
  