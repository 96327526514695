$rgcDarkBlue: #1a3f70;
$rgcLightBlue: #C1E0F4;
$rgcLightGreen: #CBDB2F;
$rgcOrange: #FDB714;
$rgcAqua: #526D6F;
$rgcRed: #F47721;
$rgcGrey: #897f79;

$linkColor: $rgcDarkBlue;
$linkHoverColor: $rgcRed;
$calloutLink: $rgcRed;