.page-login{
    display: flex;
    justify-content: center;
  
    .login{

        margin-top: 14vh;
        width: 100%;
        max-width: 600px;
        text-align: center;
        padding: 1rem;

        img {
            max-width: 240px;
            display: block;
            margin: 1rem auto;
        }

        h1{
            font-size: 1.8rem;
            font-weight: bold;
        }

        form{
            max-width: 400px;
            text-align: left;
            margin: 1.4rem auto;

            section{
                margin: 1rem 0;
            }
        }
    }

    .success{
        padding-top: 2rem;
    }
    
}