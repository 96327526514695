
.notification.success{
    .toast-header{
        background-color:#88f76b;
        color: #333;
    }
}

.notification.error{
    .toast-header{
        background-color:#ec7073;
        color: #333;
    }
}
